import { CustomizationFieldWithSpecialCondition } from '@/components/AddPlanDataGrid/useCustomizationParser'
import { WithCustomizationComponentProps } from '@/components/state/context/customizationContext'
import { CustomFieldEntryFragment, GetCustomFieldsBatchedQuery, PlanMinimalFragment, Role } from '@/generated/graphql'
import { notEmpty } from '@/util/array'
import buildLogger from '@/util/logger'
import { GridColDef, GridSingleSelectColDef, ValueOptions } from '@mui/x-data-grid-pro'
import { isArray } from 'lodash'
const logger = buildLogger('AddPlanDataGridUtils')

interface PlanGridEntry {
  readonly id: string
  readonly name: string
  readonly description: string
  readonly product_name: string | undefined
  readonly product_category: string | undefined
  readonly plan: PlanMinimalFragment
}

const planNameColumn: GridColDef = {
  headerName: 'Plan Name',
  field: 'name',
  align: 'left',
  width: 250,
  minWidth: 150,
}

const productNameColumn: GridColDef = {
  headerName: 'Product Name',
  field: 'product_name',
  align: 'left',
  width: 200,
  minWidth: 150,
}

const descriptionColumn: GridColDef = {
  headerName: 'Description',
  field: 'description',
  align: 'left',
  width: 250,
  minWidth: 150,
}

const idColumn: GridColDef = {
  headerName: 'ID',
  field: 'id',
  align: 'left',
  width: 150,
  minWidth: 100,
}

function getCustomFieldsFlattenedColumns(customFieldsOfPlans: CustomFieldEntryFragment[][]) {
  return Object.values(
    ([] as CustomFieldEntryFragment[]).concat(...customFieldsOfPlans).reduce((acc, customField) => {
      if (customField.name) {
        const valueOptions = acc[customField.name]?.valueOptions as ValueOptions[] | undefined
        const hasEntry = !!acc[customField.name] && isArray(valueOptions)
        const hasNewOption = !!customField.value && !valueOptions?.includes(customField.value)
        if (hasEntry) {
          if (hasNewOption) {
            acc[customField.name] = {
              ...acc[customField.name],
              valueOptions: [...valueOptions, customField.value] as ValueOptions[],
            }
          }
        } else {
          acc[customField.name] = {
            headerName: customField.name,
            field: customField.name,
            align: 'left',
            width: 250,
            minWidth: 150,
            valueOptions: customField.value ? [customField.value] : [],
            type: 'singleSelect',
            colSpan: 1,
          }
        }
      }
      return acc
    }, {} as Record<string, GridSingleSelectColDef>)
  )
}

function applyColumnCustomization(
  columns: GridColDef[],
  customizationProps: WithCustomizationComponentProps | undefined
): GridColDef[] {
  return customizationProps
    ? [...columns].sort((columnA, columnB) => {
        if (customizationProps.columnOrderNormalized?.[columnA.field] === undefined) {
          return 1
        }
        if (customizationProps.columnOrderNormalized?.[columnB.field] === undefined) {
          return -1
        }
        return (
          customizationProps?.columnOrderNormalized?.[columnA.field] -
          customizationProps?.columnOrderNormalized?.[columnB.field]
        )
      })
    : columns
}

export function getPlanGridTableProps({
  data,
  role,
  customFields,
  customizationProps,
}: {
  data: ReadonlyArray<PlanMinimalFragment>
  role: Role
  customFields?: GetCustomFieldsBatchedQuery['customFieldsBatched']
  customizationProps?: WithCustomizationComponentProps
}): {
  rows: PlanGridEntry[]
  columns: GridColDef[]
} {
  const customFieldsOfPlans = data.map((plan) => {
    return (customFields
      ?.find((customField) => customField?.parentObjectId === plan.id)
      ?.customFieldEntries?.filter(notEmpty) ?? []) as CustomFieldEntryFragment[]
  })

  const rows: PlanGridEntry[] = data.map((plan, index) => {
    const customFields = customFieldsOfPlans[index]
    const customFieldMap = customFields.reduce((acc, customField) => {
      if (customField.name) {
        acc[customField.name] = customField.value ?? ''
      }
      return acc
    }, {} as Record<string, string>)

    return {
      id: plan.id as string,
      name: plan.name,
      description: plan.description ?? '',
      product_name: plan.product.name ?? '',
      product_category: plan.product.productCategory?.name ?? '',
      plan: plan,
      ...customFieldMap,
    }
  })

  const productCategoryOptions: ValueOptions[] = Array.from(
    new Set(data.map((plan) => plan.product.productCategory?.name).filter(notEmpty))
  )

  const categoryColumn: GridColDef = {
    headerName: 'Category',
    field: CustomizationFieldWithSpecialCondition.ProductCategory,
    align: 'left',
    width: 200,
    minWidth: 150,
    valueOptions: productCategoryOptions,
    type: 'singleSelect',
    colSpan: 1,
  }

  const salesUserGridColumns: GridColDef[] = [planNameColumn, productNameColumn, categoryColumn, descriptionColumn]

  const fullUserPlanGridColumns: GridColDef[] = [idColumn].concat(salesUserGridColumns)

  const customFieldsFlattenedColumns = getCustomFieldsFlattenedColumns(customFieldsOfPlans)

  const columns: GridColDef[] = applyColumnCustomization(
    (role === Role.Sales ? salesUserGridColumns : fullUserPlanGridColumns).concat(customFieldsFlattenedColumns),
    customizationProps
  )

  return {
    rows,
    columns,
  }
}
