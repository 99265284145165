import { StepperFormChildren } from '@/components/BillyStepperForm/BillyStepperForm'
import InnerPageContainer from '@/components/layout/innerPageContainer'
import { preselectOpportunityByCrmId } from '@/pageComponents/orders/AmendmentOrderPage/utils'
import { OrderEditStepperButtons } from '@/pageComponents/orders/EditOrderPage/NewOrderStepperPageNavLayout'
import {
  useDryRunActions,
  useShouldRecalculateTotals,
} from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { mapAmendmentOrderUpsertArgs } from '@/pageComponents/orders/EditOrderPage/utils'
import { useUpdateCustomFieldsBatchAsync } from '@/pageComponents/orders/useUpdateCustomFieldsBatchAsync'
import Image from 'next/image'
import { useCallback, useEffect, useMemo } from 'react'
import { useErrorHandler } from '../../../components/ErrorHandler/ErrorHandler'
import { deepMutable } from '../../../components/SchemaForm/DeepMutable'
import { NavLayout } from '../../../components/layout/navLayout'
import { BreadcrumbDataList } from '../../../components/nav/breadcrumbNav'
import { ButtonDataList } from '../../../components/nav/secondaryBarWithButtons'
import PageLoadingPlaceholder from '../../../components/placeholder/pageLoadingPlaceholder'
import { useBillyRouter } from '../../../components/route/useBillyRouter'
import { useJotaiFormContext } from '../../../components/state/jotaiFormProvider'
import useJotaiOnSubmit from '../../../components/state/useJotaiOnSubmit'
import { useJotaiUrqlMutation } from '../../../components/state/useJotaiUrqlMutation'
import { useJotaiUrqlQuery } from '../../../components/state/useJotaiUrqlQuery'
import {
  DocumentTemplateType,
  DryRunAmendmentDocument,
  GetAttachmentsDocument,
  GetAttachmentsQuery,
  GetAttachmentsQueryVariables,
  GetCurrentTenantDocument,
  GetCurrentTenantQuery,
  GetCurrentTenantQueryVariables,
  GetOpportunitiesByAccountCrmIdDocument,
  GetOpportunitiesByAccountCrmIdQuery,
  GetOpportunitiesByAccountCrmIdQueryVariables,
  GetOpportunitiesByHubSpotCompanyIdDocument,
  GetOpportunitiesByHubSpotCompanyIdQuery,
  GetOpportunitiesByHubSpotCompanyIdQueryVariables,
  GetOpportunitiesBySalesforceAccountIdDocument,
  GetOpportunitiesBySalesforceAccountIdQuery,
  GetOpportunitiesBySalesforceAccountIdQueryVariables,
  ListAccountContactsDocument,
  ListAccountContactsQuery,
  ListAccountContactsQueryVariables,
  ListDiscountsDocument,
  ListDiscountsQuery,
  ListDiscountsQueryVariables,
  ListPlansDocument,
  ListPlansQuery,
  ListPlansQueryVariables,
  ListTemplatesByTypeDocument,
  ListTemplatesByTypeQuery,
  ListTemplatesByTypeQueryVariables,
  UpsertAmendmentDocument,
} from '../../../generated/graphql'
import { AmendmentOrderSchema } from '../../../util/validationSchemas'
import usePopulateOrder from '../usePopulateOrder'
import { AmendSubscriptionPageState, logger } from './AmendmentOrderPage'
import { AmendmentRightDrawerWithData } from './AmendmentRightDrawerWithData'

export function AmendmentOrderLayout({
  children,
  stepperFormProps,
}: { children?: React.ReactNode } & StepperFormChildren) {
  const jotaiForm = useJotaiFormContext<AmendSubscriptionPageState>()

  const router = useBillyRouter()
  const subscriptionId = router.query.subscriptionId as string | undefined
  const opportunityCrmId = router.query.opportunityCrmId as string | undefined

  const { hasLoaded, orderId, isNew } = usePopulateOrder({ jotaiForm })

  useJotaiUrqlQuery<AmendSubscriptionPageState, GetCurrentTenantQuery, GetCurrentTenantQueryVariables>({
    document: GetCurrentTenantDocument,
    jotaiForm,
    onData: useCallback((data, draft) => {
      draft.hasSalesforceIntegration = data.currentTenant.hasSalesforceIntegration
      draft.hasHubSpotIntegration = data.currentTenant.hasHubSpotIntegration
    }, []),
  })

  useJotaiUrqlQuery<AmendSubscriptionPageState, ListAccountContactsQuery, ListAccountContactsQueryVariables>({
    document: ListAccountContactsDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.contactList = deepMutable(data.accountContacts)), []),
    pause: useCallback((form) => !form.orderDetail.account?.id, []),
    variables: useCallback((form) => ({ accountId: form.orderDetail.account?.id || '' }), []),
  })

  useJotaiUrqlQuery<AmendSubscriptionPageState, ListAccountContactsQuery, ListAccountContactsQueryVariables>({
    document: ListAccountContactsDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.billingContactList = deepMutable(data.accountContacts)), []),
    pause: useCallback((form) => !(form.orderDetail.resoldBy?.id || form.orderDetail.account?.id), []),
    variables: useCallback(
      (form) => ({ accountId: form.orderDetail.resoldBy?.id || form.orderDetail.account?.id || '' }),
      []
    ),
  })

  useJotaiUrqlQuery<AmendSubscriptionPageState, ListPlansQuery, ListPlansQueryVariables>({
    document: ListPlansDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.plansList = deepMutable(data.plans)), []),
  })

  useJotaiUrqlQuery<
    AmendSubscriptionPageState,
    GetOpportunitiesBySalesforceAccountIdQuery,
    GetOpportunitiesBySalesforceAccountIdQueryVariables
  >({
    document: GetOpportunitiesBySalesforceAccountIdDocument,
    jotaiForm,
    onData: useCallback(
      (data, draft) => {
        draft.opportunities = deepMutable(data.opportunitiesBySalesforceAccountId)
        if (data?.opportunitiesBySalesforceAccountId) {
          preselectOpportunityByCrmId(draft, data.opportunitiesBySalesforceAccountId, opportunityCrmId)
        }
      },
      [opportunityCrmId]
    ),
    pause: useCallback(
      (form) =>
        !form.orderDetail.account?.crmId || !form.hasSalesforceIntegration || !!form.orderDetail.compositeOrderId,
      []
    ),
    variables: useCallback((form) => ({ sfdcAccountId: form.orderDetail.account?.crmId || '' }), []),
  })

  useJotaiUrqlQuery<
    AmendSubscriptionPageState,
    GetOpportunitiesByHubSpotCompanyIdQuery,
    GetOpportunitiesByHubSpotCompanyIdQueryVariables
  >({
    document: GetOpportunitiesByHubSpotCompanyIdDocument,
    jotaiForm,
    onData: useCallback(
      (data, draft) => {
        draft.opportunities = deepMutable(data.opportunitiesByHubSpotCompanyId)
        if (data?.opportunitiesByHubSpotCompanyId) {
          preselectOpportunityByCrmId(draft, data.opportunitiesByHubSpotCompanyId, opportunityCrmId)
        }
      },
      [opportunityCrmId]
    ),
    pause: useCallback(
      (form) => !form.orderDetail.account?.crmId || !form.hasHubSpotIntegration || !!form.orderDetail.compositeOrderId,
      []
    ),
    variables: useCallback((form) => ({ companyId: form.orderDetail.account?.crmId || '' }), []),
  })

  useJotaiUrqlQuery<
    AmendSubscriptionPageState,
    GetOpportunitiesByAccountCrmIdQuery,
    GetOpportunitiesByAccountCrmIdQueryVariables
  >({
    document: GetOpportunitiesByAccountCrmIdDocument,
    jotaiForm,
    onData: useCallback(
      (data, draft) => {
        draft.opportunities = deepMutable(data.opportunitiesByAccountCrmId)
        if (data?.opportunitiesByAccountCrmId) {
          preselectOpportunityByCrmId(draft, data.opportunitiesByAccountCrmId, opportunityCrmId)
        }
      },
      [opportunityCrmId]
    ),
    pause: useCallback(
      (form) =>
        !form.orderDetail.account?.crmId ||
        form.hasHubSpotIntegration ||
        form.hasSalesforceIntegration ||
        !!form.orderDetail.compositeOrderId,
      []
    ),
    variables: useCallback((form) => ({ accountCrmId: form.orderDetail.account?.crmId || '' }), []),
  })

  useJotaiUrqlQuery<AmendSubscriptionPageState, ListDiscountsQuery, ListDiscountsQueryVariables>(
    useMemo(
      () => ({
        document: ListDiscountsDocument,
        jotaiForm,
        onData: (data, draft) => (draft.discountsList = deepMutable(data.discounts)),
      }),
      [jotaiForm]
    )
  )

  useJotaiUrqlQuery<AmendSubscriptionPageState, ListTemplatesByTypeQuery, ListTemplatesByTypeQueryVariables>({
    document: ListTemplatesByTypeDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.templateList = deepMutable(data.documentTemplates)), []),
    variables: useCallback(() => ({ type: DocumentTemplateType.Order }), []),
  })

  useJotaiUrqlQuery<AmendSubscriptionPageState, GetAttachmentsQuery, GetAttachmentsQueryVariables>({
    document: GetAttachmentsDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.attachments = deepMutable(data.attachments)), []),
    pause: useCallback((form) => !form.orderDetail.account?.id, []),
    variables: useCallback((form) => ({ accountId: form.orderDetail.account?.id || '' }), []),
  })

  const breadcrumbs = useMemo(() => {
    const crumbs: BreadcrumbDataList = [
      {
        label: '',
        link: '/',
        icon: <Image src="/icons/home-sm.svg" width={20} height={20} />,
      },
      { label: 'Orders', link: '/orders' },
    ]

    if (isNew) {
      crumbs.push({ label: 'New Amendment Order' })
    } else {
      crumbs.push({ label: orderId, link: `/orders/${orderId}` })
      crumbs.push({ label: 'Edit Amendment Order' })
    }

    return crumbs
  }, [isNew, orderId])

  const upsertAmendment = useJotaiUrqlMutation({ document: UpsertAmendmentDocument, jotaiForm })

  const dryRunUpsertAmendment = useJotaiUrqlMutation({ document: DryRunAmendmentDocument, jotaiForm })

  const { updateCustomFieldsBatchAsync } = useUpdateCustomFieldsBatchAsync()

  const errorHandler = useErrorHandler()

  const { clearDryRun } = useDryRunActions()
  const save = useCallback(
    (isDryRun: boolean) => (form: AmendSubscriptionPageState) => {
      logger.debug({ msg: 'saving', isDryRun })

      const mutationOrder = mapAmendmentOrderUpsertArgs(form, isNew)

      if (isDryRun) {
        dryRunUpsertAmendment({
          variables: { order: mutationOrder },
          onData: (data, draft) => {
            const dryRunOrderDetail = data.upsertAmendment
            draft.orderDetail = deepMutable({ ...dryRunOrderDetail, termLength: undefined })
            clearDryRun()
          },
        })
      } else {
        upsertAmendment({
          variables: { order: mutationOrder },
          onData: (data) => {
            async function doAsync() {
              await updateCustomFieldsBatchAsync(deepMutable(data.upsertAmendment.lineItems))
              if (isNew) {
                history.replaceState(null, '', `/orders/${data.upsertAmendment.id}/edit`)
                router.push(`/orders/${data.upsertAmendment.id}`)
              } else {
                router.push(`/orders/${orderId}?amendmentData=true`)
              }
            }
            doAsync().catch(errorHandler)
          },
        })
      }
    },
    [
      dryRunUpsertAmendment,
      isNew,
      orderId,
      router,
      upsertAmendment,
      errorHandler,
      updateCustomFieldsBatchAsync,
      clearDryRun,
    ]
  )
  const onSubmit = useJotaiOnSubmit(
    useMemo(
      () => ({
        atom: jotaiForm.atom,
        onSubmit: (value: AmendSubscriptionPageState) => save(false)(value),
        schema: AmendmentOrderSchema,
      }),
      [jotaiForm, save]
    )
  )

  const buttonDataList: ButtonDataList = useMemo(
    () => [
      {
        label: 'Cancel',
        onClick: subscriptionId ? `/subscriptions/${subscriptionId}` : `/orders/${orderId}`,
        color: 'inherit',
        buttonProps: { variant: 'outlined' },
      },
      {
        label: 'Save Draft',
        onClick: onSubmit,
      },
    ],
    [onSubmit, orderId, subscriptionId]
  )

  const shouldRecalculateTotals = useShouldRecalculateTotals()

  const onDryRun = useJotaiOnSubmit({
    atom: jotaiForm.atom,
    onSubmit: useCallback((value: AmendSubscriptionPageState) => save(true)(value), [save]),
    schema: AmendmentOrderSchema,
    onError: clearDryRun,
  })

  useEffect(() => {
    logger.trace({ msg: 'should I dry run?', shouldRecalculateTotals })
    if (shouldRecalculateTotals) {
      onDryRun()
    }
  }, [jotaiForm, onDryRun, shouldRecalculateTotals, errorHandler])

  return (
    <NavLayout
      breadcrumbs={breadcrumbs}
      actionButtons={
        stepperFormProps ? (
          <OrderEditStepperButtons buttonDataList={buttonDataList} stepperFormProps={stepperFormProps} />
        ) : (
          buttonDataList
        )
      }
      rightDrawer={AmendmentRightDrawerWithData}
    >
      <InnerPageContainer>
        <PageLoadingPlaceholder isLoading={!isNew && !hasLoaded}>{children}</PageLoadingPlaceholder>
      </InnerPageContainer>
    </NavLayout>
  )
}
