import { EditOrderTimelineCard } from '@/pageComponents/orders/EditOrderPage/cards/EditOrderTimelineCard/EditOrderTimelineCard'
import { Stack } from '@mui/system'
import { memo } from 'react'
import buildLogger from '../../../util/logger'
import { IncludedTermsCard } from '../IncludedTerms/IncludedTermsCard'
import DocumentMasterTemplateCard from '../documentMasterTemplateCard'
import OrderDiscounts from '../orderDiscounts'
import { NewOrderFormData } from './NewOrderPage'
import { BillyOrderDetailsCard } from './cards/BillyOrderDetailsCard/BillyOrderDetailsCard'
import { OrderEditBillingCard } from './cards/OrderEditBillingCard/OrderEditBillingCard'
import { OrderEditItemsCard } from './cards/OrderEditItemsCard'
import { EditOrderCustomFieldsCard } from '@/pageComponents/orders/EditOrderPage/cards/EditOrderCustomFieldsCard/EditOrderCustomFieldsCard'

const logger = buildLogger('NewOrderPageForm')

export function NewOrderPageFormWithoutMemo(): JSX.Element {
  return (
    <Stack rowGap={3}>
      <BillyOrderDetailsCard parentLocator="order::new::" />
      <EditOrderTimelineCard />
      <EditOrderCustomFieldsCard />
      <OrderDiscounts />
      <OrderEditItemsCard parentLocator="order::new::" />
      <OrderEditBillingCard parentLocator="order::new::" />
      <DocumentMasterTemplateCard />
      <IncludedTermsCard<NewOrderFormData> />
    </Stack>
  )
}

const NewOrderPageForm = memo(NewOrderPageFormWithoutMemo)
export default NewOrderPageForm
